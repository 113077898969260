<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <select v-model="search_data.hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected disabled :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-sm text-center" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Hub Name</th>
              <th>Purpose Type</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Fuel Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, i) in requisitions.data" :key="i">
              <td style="width: 3%">{{ requisitions.from + i}}</td>
              <td>{{ requisition.hub.name }}</td>
              <td>{{ requisition.fuel_purpose_type.fuel_purpose_type_desc }}</td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td style="width: 12%">
                <a-tag color="#E0A800" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED') ">Pending</a-tag>
                <a-tag color="#f50" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_REJECTED')">Rejected</a-tag>
                <a-tag color="#87d068" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && requisition.fuel_requisition_status.includes('ADMIN_APPROVED')">Approved</a-tag>
                <a href="javascript: void(0);" v-b-modal.fuel-requisition-status-modal @click="$bvModal.show('fuel-requisition-status-modal'), status(requisition.max_fuel_requisition_status, requisition)" class="btn btn-sm btn-info">
                  <i class="fa fa-level-up"/>
                </a>
              </td>
              <td>
                <a-tag color="#f50" v-if="requisition.fuel_collection_status == 'Collect'">Collect</a-tag>
                <a-tag color="#87d068" v-else>Collected</a-tag>
                <span v-if="requisition.fuel_collection_picture">
                   <a :href="requisition.fuel_collection_picture_url" target="_blank" download>
                  <img :src="requisition.fuel_collection_picture_url" alt="" class="rounded " width="40" height="40"/>
                </a>
                </span>
              </td>
              <td style="width: 32%">
                <a-button :loading="btnAdminReject && i == index" v-if="requisition.fuel_requisition_status.includes('ADMIN_PENDING') && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED') && !requisition.fuel_requisition_status.includes('ADMIN_REJECTED')" class="btn btn-danger btn-sm mr-1" @click.prevent="adminRejectStatus(requisition.id, index = i)"><i class="fa fa-times"></i> Reject</a-button>
                <router-link v-if="(requisition.fuel_requisition_status.includes('ADMIN_PENDING') || requisition.fuel_requisition_status.includes('ADMIN_REJECTED')) && !requisition.fuel_requisition_status.includes('ADMIN_APPROVED')&& !requisition.fuel_requisition_status.includes('ADMIN_REJECTED')" :to="{ name: 'adminHubFuelRequisitionUpdate', params: { fuel_requisition_id: requisition.id } }" class="btn btn-sm btn-success mr-1" tag="a">
                  <i class="fe fe-edit"/> Update
                </router-link>
                <router-link :to="{ name: 'hubFuelRequisitionView', params: { fuel_requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-1" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
                <a-button class="btn btn-sm btn-success mr-1" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(requisition.id, index = i)"> <i class="fa fa-file-pdf-o mr-1"></i> Download</a-button>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :requisition="requisition"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/fuelRequisition/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      hubs: [],
      requisitions: {},
      requisition: {},
      loading: false,
      btnLoading: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      index: -1,
      btnAdminReject: false,
      pdfDownloadLoader: false,
      search_data: {
        hub_id: '',
        date_range: '',
      },
    }
  },
  mounted() {
    this.codes()
  },
  methods: {
    codes() {
      apiClient.get('api/user/codes')
        .then(response => {
          const data = response.data
          this.hubs = data.hubs
        })
        .catch(error => {
          console.log(error)
        })
    },
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.flag = true
      this.loading = true
      this.btnLoading = true
      apiClient.post('api/admin/hub/pending-fuel-requisition/search', this.search_data)
        .then(response => {
          this.btnLoading = false
          this.loading = false
          this.flag = true
          this.requisitions = response.data.requisitions
        })
        .catch(error => {
          console.log(error)
        })
    },
    getResults(page = 1) {
      apiClient.post('api/admin/hub/pending-fuel-requisition/search?page=' + page, this.search_data)
        .then(response => {
          this.requisitions = response.data.requisitions
        })
    },
    pdfDownload(requisitionId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/fuel-requisition/pdf-download/' + requisitionId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Fuel Requisition.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    adminRejectStatus(fuelRequisitionId, index) {
      if (confirm('Do you really want to reject?')) {
        this.btnAdminReject = true
        apiClient.post('api/admin/hub/fuel-requisition/reject', { fuelRequisitionId: fuelRequisitionId }).then(response => {
          this.btnAdminReject = false
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.search()
            }
          } else {
            this.$notification.error({
              message: 'Fuel Requisition Rejected',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    status(fuelRequisitionStatus, requisition) {
      this.current = 0
      this.requisition = ''
      this.current = fuelRequisitionStatus
      this.requisition = requisition
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
table tr td{
  word-wrap: break-word
}
</style>
